<template>
  <div class="contact-page" ref="contactContainer">
    <div class="contact-section">
      <h3>{{ getString('title') }}</h3>
      <p>{{ getString('description') }}</p>
      <form @submit.prevent="submitForm">
        <label for="name">{{ getString('name') }}:</label>
        <input type="text" id="name" v-model="formData.name" required>

        <label for="email">{{ getString('email') }}:</label>
        <input type="email" id="email" v-model="formData.email" required>

        <label for="message">{{ getString('message') }}:</label>
        <textarea id="message" v-model="formData.message" required></textarea>

        <button type="submit" class="nes-btn is-primary">{{ getString('submit') }}</button>
      </form>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { useAppStore } from '../stores/app';
import i18nData from '../i18n/strings';

export default {
  name: 'ContactUs',
  setup() {
      const appStore = useAppStore();
      return { appStore };
    },
  data() {
    return {
      formData: {
        name: '',
        email: '',
        message: ''
      }
    };
  },
  methods: {
    getString(key) {
        return i18nData[this.appStore.language]['contact'][key];
      },
    submitForm() {
      this.appStore.createIssue(this.formData);
        this.formData = {
          name: '',
          email: '',
          message: ''
        };
        alert("👍");
    },
    animateElements() {
      const container = this.$refs.contactContainer;
      const elements = container.querySelectorAll('.contact-section, h3, p, form, label, input, textarea, button');
      gsap.from(elements, { opacity: 0, y: 50, duration: 1, stagger: 0.2, ease: 'power3.inOut' });
    }
  },
  mounted() {
    this.animateElements();
  }
}
</script>

<style scoped>
.contact-page {
  height: 85vh;
  width: 75%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-section {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 600px;
}

h3 {
  margin-bottom: 20px;
  padding-top: 1rem;
}

label {
  display: block;
  margin: 10px 0 5px;
}

p {
  font-size: 0.8rem;
}

input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
}

button {
  padding: 10px 20px;
  width: 100%;
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
  .contact-page {
    width: 100%;
    height: 120vh;
  }

  .contact-section {
    padding: 15px;
  }

  h3 {
    font-size: 1rem;
  }

  p, label {
    font-size: 0.7rem;
  }

  input, textarea, button {
    width: 100%;
  }
}
</style>
