import { defineStore } from 'pinia';

export const useAppStore = defineStore('app', {
    state: () => ({
        language: 'en', // default language
    }),
    getters: {
        getLanguage() {
            return this.language;
        },
    },
    actions: {
        setLanguage(newLanguage) {
            this.language = newLanguage;
        },
        createIssue({name, email, message}) {
            // https POST call to redmine
            fetch('https://redmine.go-cybernation.cloud/issues.json', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Redmine-API-Key': "0b1cefd26a1fe6286d9a1912f86c1ae2edc5dd11",
                },
                body: JSON.stringify({
                    issue: {
                        project_id: 18,
                        subject: `Website contact from ${name}`,
                        description: `Email: ${email}\n\n${message}`,
                        priority_id: 1
                    }
                })
            })
            
        },
    }
});