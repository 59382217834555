import { createRouter, createWebHistory } from 'vue-router';
import Home from '../components/Home.vue';
//import About from '../components/About.vue';
import Contact from '../components/Contact.vue';
import Products from '../components/Products.vue';
import OurTeam from '../components/OurTeam.vue';


const routes = [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact,
    },
    {
      path: '/products',
      name: 'Products',
      component: Products,
    },
    {
        path: '/team',
        name: 'Team',
        component: OurTeam,
      },
  ];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
