<template>
    <div class="language-selector nes-select">
      <label for="language" class="sr-only">Select Language</label>
      <select
        class="nes-select"
        id="language"
        :value="language"
        @change="updateLanguage"
        aria-label="Language Selector"
      >
        <option class="nes-pointer" v-for="(language, code) in languages" :key="code" :value="code">
          {{ language.flag }}
        </option>
      </select>
    </div>
  </template>
  
  <script>
  import { useAppStore } from '../stores/app';
  
  export default {
    setup() {
      const appStore = useAppStore();
      const languages = {
        en: {name: 'English', flag: '🇬🇧'},
        fr: {name: 'Français', flag: '🇫🇷'},
        es: {name: 'Español', flag: '🇪🇸'},
        de: {name: 'Deutsch', flag: '🇩🇪'},
        jp: {name: '日本語', flag: '🇯🇵'},
        ru: {name: 'русский', flag: '🇷🇺'},
      };
  
      const updateLanguage = (event) => {
        appStore.setLanguage(event.target.value);
      };
  
      return {
        languages,
        language: appStore.language,
        updateLanguage,
      };
    },
  };
  </script>
  
  <style scoped>
  .language-selector {
    font-family: 'Press Start 2P';
    color: #fff;
    padding: 10px;
    display: inline-block;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1000;
    width: 7vw;
  }
  
  select {
    background: #000;
    color: #fff;
    padding: 5px;
    font-family: inherit;
    font-size: 14px;
  }
  
  select:focus {
    outline: none;
    border-color: #ff0000;
  }
  
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  </style>
  