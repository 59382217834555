import { createApp } from 'vue';
import { createPinia } from 'pinia'
import App from './App.vue';
import router from './router';
import 'nes.css/css/nes.min.css';
import 'nes.icons/css/nes-icons.css';

const pinia = createPinia()
const app = createApp(App)

app.use(pinia)
app.use(router);
app.mount('#app');