<template>
  <div id="intro">
    <div class="fullscreen" ref="fullscreen">
      <LanguageSelector />
      <button @click="skipIntro" class="nes-btn is-primary skip-button">{{ getString('skip') }}</button>
      <h1 ref="title">{{ getString('title') }}</h1>
      <div ref="scrollText" class="scroll-text">
        <p>{{ getString('p1') }}</p>
        <p>{{ getString('p2') }}</p>
        <br>
        <br>
        <br>
        <br>
        <p>{{ getString('p3') }}</p>
        <p>{{ getString('p4') }}</p>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { useAppStore } from '../stores/app';
import i18nData from '../i18n/strings';
import LanguageSelector from './LanguageSelector.vue';

export default {
  name: 'IntroAnimation',
  components: {
    LanguageSelector,
  },
  setup() {
    const appStore = useAppStore();
    return { appStore };
  },
  mounted() {
    this.playIntro();
  },
  methods: {
    getString(key) {
      return i18nData[this.appStore.language]['intro'][key];
    },
    playIntro() {
      const timeline = gsap.timeline();
      timeline
        .from(this.$refs.title, { duration: 2, opacity: 0, ease: 'power2.inOut' })
        .to(this.$refs.title, { duration: 1, opacity: 0, delay: 1, ease: 'power2.inOut' })
        .from(this.$refs.scrollText, { duration: 40, y: '100%', ease: 'linear' })
        .to({}, { duration: 5 })
        .to(this.$refs.fullscreen, { duration: 5, opacity: 0, onComplete: this.endIntro });
    },
    endIntro() {
      this.$emit('end-intro');
    },
    skipIntro() {
      gsap.killTweensOf(this.$refs.title);
      gsap.killTweensOf(this.$refs.scrollText);
      gsap.to(this.$refs.fullscreen, { duration: 1, opacity: 0, onComplete: this.endIntro });
    },
  },
  data() {
    return {
      i18nData,
    };
  },
};
</script>

<style scoped>
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background: #212529;
  background-image: url('../assets/intro-bg.gif');
  background-size: cover;
  background-color: #212529;
  color: #212529;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 1000;
}

h1 {
  font-size: 3rem;
  margin: 0;
  color: rgb(253, 173, 200);
}

.scroll-text {
  position: absolute;
  bottom: 0;
  width: 80%;
  max-width: 80%;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.5;
  opacity: 2;
}

.scroll-text p {
  margin: 1rem 0;
  color: white;
}

.skip-button {
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 0.5;
  animation: scale-up-down 2s infinite;
  z-index: 1100; /* Ensure the skip button is always on top */
  pointer-events: auto; /* Ensure the button is always clickable */
}

@keyframes scale-up-down {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

/* Media Queries for Responsive Adjustments */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem; /* Smaller title on tablet and mobile devices */
  }

  .scroll-text {
    font-size: 1.2rem; /* Adjust scroll text size for better readability */
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.5rem; /* Even smaller title on smaller screens */
  }

  .scroll-text {
    font-size: 1rem; /* Further adjust scroll text size */
  }
}
</style>
