<template>
  <nav class="nes-container is-dark">
    <div class="nav-links-container" @click="toggleMenu">
      <div class="burger-menu nes-pointer" :class="{ 'is-active': menuOpen }">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="nes-container is-rounded nav-links" :class="{ 'open': menuOpen }">
        <router-link exact-active-class="active-link" to="/">{{ getString("home") }}</router-link>
        <router-link exact-active-class="active-link" to="/team">{{ getString("team") }}</router-link>
        <router-link exact-active-class="active-link" to="/products">{{ getString("products") }}</router-link>
        <router-link exact-active-class="active-link" to="/contact">{{ getString("contact") }}</router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import { useAppStore } from '../stores/app';
import i18nData from '../i18n/strings';

export default {
  setup() {
    const appStore = useAppStore();
    return { appStore };
  },
  data() {
    return {
      getString: (key) => {
        return i18nData[this.appStore.language]['nav'][key];
      },
      menuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
  },
  name: 'NavBar',
};
</script>

<style scoped>
nav {
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  grid-column-start: span 3;
  grid-row-start: span 1;
}

.nav-links-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
}

.burger-menu {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 20px;
  cursor: pointer;
  margin-bottom: 1rem;
}

.burger-menu span {
  width: 100%;
  height: 3px;
  background-color: #f50057;
}

.nav-links {
  display: flex;
  gap: 1rem;
}

a + a {
  margin-left: 3rem;
}

a {
  color: #f50057;
}

.active-link {
  animation: blink 1s infinite;
  color: #f50057;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

/* Media Queries for Tablet and Mobile */
@media (max-width: 768px) {
  .nav-links {
    flex-direction: column;
    align-items: center;
    display: none;
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
  }

  .nav-links.open {
    display: flex;
  }

  a + a {
    margin-left: 0;
    margin-top: 1rem;
  }

  .burger-menu {
    display: flex;
  }
}

@media (max-width: 480px) {
  .nav-links-container {
    width: 100%;
  }

  .burger-menu {
    width: 25px;
    height: 15px;
  }

  .burger-menu span {
    height: 2px;
  }
}

/* Content area adjustments */
.main-content {
  padding-top: 60px; /* Adjust this value based on the height of your navbar */
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

@media (max-width: 768px) {
  .main-content {
    padding-top: 70px; /* Adjust for mobile with open navbar */
  }
}
</style>
