<template>
  <div class="carousel" ref="sectionsContainer">
    <div class="carousel-slide" v-for="(section, index) in sections" :key="index">
      <div v-if="index === currentSlide">
        <h3>{{ section.title }}</h3>
        <br>
        <p v-if="section.type === 'text'">{{ section.content }}</p>
        <ul v-if="section.type === 'list'">
          <li v-for="(item, i) in section.items" :key="i">{{ item }}</li>
        </ul>
      </div>
    </div>
    <div class="carousel-nav">
      <i v-if="currentSlide !== 0" class="nes-icon caret-left nes-pointer" @click="prevSlide" :disabled="currentSlide === 0"></i>
      <i v-else class="nes-icon"></i>
      <i v-if="currentSlide !== sections.length - 1" class="nes-icon caret-right nes-pointer" @click="nextSlide" :disabled="currentSlide === sections.length - 1"></i>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { useAppStore } from '../stores/app';
import i18nData from '../i18n/strings';

export default {
  name: 'HelloWorld',
  setup() {
    const appStore = useAppStore();
    return { appStore };
  },
  data() {
    return {
      currentSlide: 0,
      isScrolling: false,
      inAnimation: true,
    };
  },
  computed: {
    sections() {
      const language = this.appStore.language;
      return [
        { title: this.getString("title1", language), type: 'text', content: this.getString("content1", language) },
        { title: this.getString("title2", language), type: 'text', content: this.getString("content2", language) },
        { title: this.getString("title3", language), type: 'text', content: this.getString("content3", language) },
      ];
    }
  },
  methods: {
    getString(key) {
      return i18nData[this.appStore.language]['home'][key];
    },
    showSlide(index) {
      this.inAnimation = true;
      setTimeout(() => {
        this.inAnimation = false;
      }, 1050);
      const container = this.$refs.sectionsContainer;
      const slides = container.querySelectorAll('.carousel-slide');
      gsap.to(slides, { duration: 1, opacity: 0 });
      gsap.from(slides[index], { opacity: 0, duration: 1, ease: 'power3.inOut' });
      let direction = index % 2 === 0 ? 1000 : -1000;
      gsap.from(slides, { x: direction, duration: 1, ease: 'power3.inOut' });
      this.currentSlide = index;
      gsap.to(slides, { duration: 1, opacity: 1, ease: 'power3.inOut' });
    },
    prevSlide() {
      if (this.currentSlide > 0 && !this.inAnimation) {
        this.showSlide(this.currentSlide - 1);
      }
    },
    nextSlide() {
      if (this.currentSlide < this.sections.length - 1 && !this.inAnimation) {
        this.showSlide(this.currentSlide + 1);
      }
    },
  },
  mounted() {
    this.showSlide(this.currentSlide);
  },
}
</script>

<style scoped>
.carousel {
  height: 70vh;
  overflow: hidden;
  position: relative;
}

.carousel-slide {
  height: 73vh;
  padding-left: 20vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  width: 80%;
  top: 0;
  left: 0;
}

.carousel-nav {
  position: absolute;
  padding-left: 20vw;
  bottom: 20px;
  width: 80%;
  display: flex;
  justify-content: space-between;
}

h3 {
  margin: 20px 0;
  font-style: italic;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin: 10px 0;
}

i {
  color: white;
  border: none;
}

img {
  width: 24px;
  height: 24px;
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
  .carousel {
    min-height: 75vh;
    padding: 20px 0;
  }

  .carousel-slide {
    position: relative;
    width: 90%;
    padding-left: 0;
    padding-right: 0;
    height: auto;
    transform: translate(5%, 0);
  }

  .carousel-nav {
    width: 100%;
    padding: 0 10px;
    bottom: 10px;
    justify-content: space-between;
  }

  h3 {
    font-size: 1rem;
  }

  ul {
    padding: 0 10px;
  }

  li {
    margin: 5px 0;
  }

  i {
    font-size: 1rem;
  }

  p {
    font-size: 0.6rem;
  }
}
</style>
