<template>
  <div id="app">
    <IntroAnimation v-if="showIntro" @end-intro="showIntro = false" />
    <transition name="fade">
    <div v-if="!showIntro">
      <NavBar />
      <LanguageSelector />
      <router-view />
      <Footer />
    </div>
  </transition>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
import Footer from './components/Footer.vue';
import IntroAnimation from './components/IntroAnimation.vue';
import LanguageSelector from './components/LanguageSelector.vue';

export default {
  name: 'App',
  components: {
    NavBar,
    Footer,
    IntroAnimation,
    LanguageSelector,
  },
  data() {
    return {
      showIntro: true,
    };
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Press+Start+2P');
#app {
  font-family: 'Press Start 2P';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #212529;
  color: white;
  height: auto;
  min-height: 100vh;
  grid-template-columns: 15% 70% 15%;
  grid-template-rows: 10% 80% 10%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
